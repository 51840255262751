export default [
    {
        path: 'backup-to-org-comparison',
        name: 'backup-to-org-comparison',
        redirect: { name: 'backup-to-org-comparison.index' },
        component: () => import('@/layouts/EmptyRouterView.vue'),
        meta: {
            breadcrumbs: [
                { icon: 'arrow_up_down', title: 'Comparison' },
                { title: 'Backup to org', to: 'backups-comparison' },
            ],
        },
        children: [
            {
                path: 'list',
                name: 'backup-to-org-comparison.index',
                component: () => import('@/pages/comparison/index.vue'),
            },
            {
                path: 'logs',
                name: 'backup-to-org-comparison.logs',
                component: () => import('@/pages/logs/index.vue'),
                meta: {
                    breadcrumbs: [{ title: 'Logs' }],
                },
            },
            {
                path: ':projectName',
                name: 'backup-to-org-comparison.create',
                meta: {
                    breadcrumbs: [{ title: 'Create' }],
                },
                component: () => import('@/pages/comparison/new.vue'),
            },
        ],
    },
];
