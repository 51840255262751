import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { randomID } from '@/utils';
let AppModalContainer = class AppModalContainer extends Vue {
    constructor() {
        super(...arguments);
        this.modals = [];
    }
    created() {
        this.$root.$modal.setContainer(this);
    }
    showModal(id) {
        if (this.$refs[id]) {
            if (Array.isArray(this.$refs[id])) {
                this.$refs[id][0].show();
            }
            else {
                this.$refs[id].show();
            }
        }
    }
    add(modal) {
        const id = randomID();
        this.modals.push({ ...modal, id });
        this.$nextTick(() => this.showModal(id));
    }
    resolve(id, data) {
        const index = this.modals.findIndex(v => v.id === id);
        if (index !== -1) {
            this.modals[index].resolver(data);
            this.modals = this.modals.filter(modal => modal.id !== id);
        }
    }
};
AppModalContainer = __decorate([
    Component({})
], AppModalContainer);
export default AppModalContainer;
