import Vue from 'vue';
import { OrganizationsModule } from '@/store/modules/organizations';
const organizationResolver = async (to, from, next) => {
    try {
        Vue.loading('Loading organization info');
        await OrganizationsModule.fetchCurrentOrganization(+to.params.id);
    }
    catch (error) {
        Vue.loading(false);
        Vue.toast.error(error.message);
        next(false);
    }
};
export default organizationResolver;
