import FIcon from '@/components/FIcon.vue';
import FLoading from '@/components/FLoading.vue';
import FTable from '@/components/FTable.vue';
import FDualList from '@/components/FDualList.vue';
import FSearchField from '@/components/FSearchField.vue';
import FPagination from '@/components/FPagination.vue';
import FTabs from '@/components/FTabs.vue';
import FTab from '@/components/FTab.vue';
import FCheckbox from '@/components/FCheckbox.vue';
import FNotifications from '@/components/FNotifications.vue';
import FModal from '@/components/FModal.vue';
import FRadio from '@/components/FRadio.vue';
import FOverlay from '@/components/FOverlay.vue';
import FRecordItem from '@/components/FRecordItem.vue';
import FBlock from '@/components/FBlock.vue';
import FRecordField from '@/components/FRecordField.vue';
import FPaginator from '@/components/FPaginator.vue';
export default {
    // eslint-disable-next-line no-shadow,@typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
    install(Vue) {
        Vue.component('f-icon', FIcon);
        Vue.component('f-loading', FLoading);
        Vue.component('f-table', FTable);
        Vue.component('f-dual-list', FDualList);
        Vue.component('f-search-field', FSearchField);
        Vue.component('f-pagination', FPagination);
        Vue.component('f-tabs', FTabs);
        Vue.component('f-tab', FTab);
        Vue.component('f-checkbox', FCheckbox);
        Vue.component('f-notifications', FNotifications);
        Vue.component('f-modal', FModal);
        Vue.component('f-radio', FRadio);
        Vue.component('f-overlay', FOverlay);
        Vue.component('f-record-item', FRecordItem);
        Vue.component('f-block', FBlock);
        Vue.component('f-record-field', FRecordField);
        Vue.component('f-paginator', FPaginator);
    },
};
