import { __decorate } from "tslib";
import { Module, VuexModule, getModule, Mutation } from 'vuex-module-decorators';
import { namespace } from 'vuex-class';
import { APP_MODULE_NAMESPACE } from '@/store/modules/app.definitions';
import store from '@/store';
let App = class App extends VuexModule {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.loadingText = '';
    }
    setLoading(value) {
        this.loading = value;
        if (!value) {
            this.loadingText = '';
        }
    }
    setLoadingText(text) {
        this.loadingText = text;
    }
};
__decorate([
    Mutation
], App.prototype, "setLoading", null);
__decorate([
    Mutation
], App.prototype, "setLoadingText", null);
App = __decorate([
    Module({
        name: APP_MODULE_NAMESPACE,
        namespaced: true,
        dynamic: true,
        store,
    })
], App);
export const AppModule = getModule(App);
export const AppModuleStore = namespace(APP_MODULE_NAMESPACE);
