import playgroundRoutes from '@/router/modules/playground';
import authRoutes from '@/router/modules/auth';
import organizationsRoutes from '@/router/modules/organizations';
import rootRoutes from '@/router/modules/root';
import mainRoutes from '@/router/modules/main';
/**
 * Preload layouts
 */
import MainLayout from '@/layouts/Main.vue';
import AuthLayout from '@/layouts/auth.vue';
export default [
    ...playgroundRoutes,
    ...rootRoutes,
    {
        path: '/auth',
        component: AuthLayout,
        children: [...authRoutes],
    },
    {
        path: '/',
        component: MainLayout,
        redirect: { name: 'main.home' },
        children: [...organizationsRoutes, ...mainRoutes],
    },
    {
        name: 'PageNotFound',
        path: '*',
        component: () => import('@/pages/page-not-found.vue'),
    },
];
