export default [
    {
        path: 'loading',
        name: 'auth.loading',
        component: () => import('@/pages/auth/loading.vue'),
    },
    {
        path: 'expired',
        name: 'auth.expired',
        component: () => import('@/pages/auth/expired.vue'),
    },
    {
        path: 'license',
        name: 'auth.license',
        component: () => import('@/pages/auth/license.vue'),
    },
    {
        path: 'trial',
        name: 'auth.trial',
        component: () => import('@/pages/auth/trial.vue'),
    },
];
