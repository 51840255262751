import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import { multiGuard } from '@/utils/multiguard';
import licenseGuard from '@/router/guards/license.guard';
import isAuthenticatedGuard from '@/router/guards/is-authenticated.guard';
const router = new VueRouter({
    mode: 'history',
    routes,
});
Vue.use(VueRouter);
Vue.router = router;
router.beforeEach(multiGuard([licenseGuard, isAuthenticatedGuard]));
router.afterEach(() => {
    Vue.loading?.(false);
});
export default router;
