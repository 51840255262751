import { __decorate } from "tslib";
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import AuthService from '@/services/auth.service';
let AppSidebar = class AppSidebar extends Vue {
    constructor() {
        super(...arguments);
        this.links = [
            { title: 'Dashboard', icon: 'category', path: 'dashboard', namespace: 'dashboard', active: false },
            { title: 'Data Sets', icon: 'layers', path: 'data-sets.index', namespace: 'data-sets', active: false },
            { title: 'Templates', icon: 'template', path: 'org.templates2.index', namespace: 'org.templates2', active: false },
            { title: 'Backups', icon: 'database', path: 'backups', namespace: 'backups', active: false },
            { title: 'Restore', icon: 'history', path: 'restore.index', namespace: 'restore', active: false },
            {
                title: 'Comparison',
                icon: 'arrow_up_down',
                active: false,
                children: [
                    {
                        title: 'Backup to org',
                        path: 'backup-to-org-comparison',
                    },
                    {
                        title: 'Backup to backup',
                        path: 'backups-comparison',
                    },
                ],
            },
            {
                title: 'Organization Settings',
                icon: 'server',
                path: 'organization-settings',
                namespace: 'organization-settings',
                active: false,
            },
        ];
    }
    get isOrganizationNavigation() {
        return this.$route.path.startsWith('/org');
    }
    async logout() {
        try {
            await AuthService.logOut();
        }
        catch (error) {
            this.$toast.error(error.message);
        }
    }
    onRouteChanged(route) {
        for (const link of this.links) {
            let routeElement = false;
            if (link.children) {
                for (const subLink of link.children) {
                    routeElement = !!route.matched.find(r => r.name === subLink.path);
                    if (routeElement) {
                        break;
                    }
                }
                link.active = routeElement;
            }
            else {
                routeElement = !!route.matched.find(r => r.name === link.namespace || r.name?.startsWith(`${link.namespace}.`));
            }
            link.active = routeElement;
        }
    }
};
__decorate([
    Prop({
        default: false,
    })
], AppSidebar.prototype, "mobile", void 0);
__decorate([
    PropSync('shown')
], AppSidebar.prototype, "isShown", void 0);
__decorate([
    Watch('$route', { immediate: true })
], AppSidebar.prototype, "onRouteChanged", null);
AppSidebar = __decorate([
    Component({})
], AppSidebar);
export default AppSidebar;
