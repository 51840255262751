import moment from 'moment';
import { BackupMode } from '@flosum-backup/contracts';
export const DATE_FORMAT = 'MMM D, YYYY H:mmA';
export function formatDate(date) {
    return moment(date).format(DATE_FORMAT);
}
export function randomID() {
    return (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase();
}
export function chunkArray(array, size) {
    if (array.length === 0) {
        return [];
    }
    if (array.length <= size) {
        return [array];
    }
    return [array.slice(0, size), ...chunkArray(array.slice(size), size)];
}
export function normalizeMode(mode) {
    switch (mode) {
        case BackupMode.Full:
            return 'Full';
        case BackupMode.Incremental:
            return 'Incremental';
        case BackupMode.Template:
            return 'Template';
        default:
            return '-';
    }
}
export function normalizeBytes(bytes) {
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB'];
    if (!bytes)
        return '0 B';
    const i = +Math.floor(Math.log(+bytes) / Math.log(1024));
    return `${(+bytes / 1024 ** i).toFixed(2)} ${sizes[i]}`;
}
export function getAppURIs() {
    const client = window.location.origin;
    const server = window.location.hostname === 'localhost' ? 'http://localhost:5000' : window.location.origin;
    return {
        client,
        server,
    };
}
export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
export function convertToBase64(input) {
    return window.btoa(unescape(encodeURIComponent(input)));
}
// eslint-disable-next-line @typescript-eslint/ban-types
export function isEmptyObject(input) {
    if (typeof input !== 'object') {
        throw new TypeError(`Input should be an object. Received: ${typeof input}`);
    }
    return Object.keys(input).length === 0 && Object.entries(input).length === 0 && input.constructor === Object;
}
