import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
let FTab = class FTab extends Vue {
    constructor() {
        super(...arguments);
        this.isActive = false;
    }
};
__decorate([
    Prop({
        required: true,
    })
], FTab.prototype, "title", void 0);
__decorate([
    Prop({
        default: null,
    })
], FTab.prototype, "icon", void 0);
FTab = __decorate([
    Component({})
], FTab);
export default FTab;
