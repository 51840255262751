import { __decorate } from "tslib";
import { Vue, Component, Model, Prop } from 'vue-property-decorator';
let FRadio = class FRadio extends Vue {
    handleInput(event) {
        const isChecked = event.target.checked;
        this.$emit('change', isChecked && this.value);
    }
};
__decorate([
    Model('change', {
        default: null,
    })
], FRadio.prototype, "state", void 0);
__decorate([
    Prop({
        required: true,
    })
], FRadio.prototype, "value", void 0);
__decorate([
    Prop({
        default: false,
    })
], FRadio.prototype, "disabled", void 0);
FRadio = __decorate([
    Component({})
], FRadio);
export default FRadio;
