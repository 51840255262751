import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import { OrganizationsModule } from '@/store/modules/organizations';
let AppBreadcrumbs = class AppBreadcrumbs extends Vue {
    constructor() {
        super(...arguments);
        this.breadcrumbs = [];
    }
    get organizationName() {
        return OrganizationsModule.currentOrganization?.name || '';
    }
    get isOrganizationOpen() {
        return this.$route.path.startsWith('/org');
    }
    navigateTo(item) {
        if (!item.to || item.to === this.$route.name) {
            return;
        }
        this.$router.push({
            name: item.to,
            query: {
                ...(item.preserveQuery ? this.$route.query : {}),
            },
        });
    }
    mounted() {
        this.update();
    }
    onRouteUpdate() {
        this.update();
    }
    createItem(item) {
        if (typeof item === 'function') {
            this.breadcrumbs.push(item(this.$route));
        }
        else {
            this.breadcrumbs.push(item);
        }
    }
    update() {
        const { matched } = this.$route;
        this.breadcrumbs = [];
        this.createItem({
            icon: 'logotype',
            to: 'main.home',
        });
        if (this.organizationName && this.isOrganizationOpen) {
            this.createItem({
                title: this.organizationName,
            });
        }
        for (const matchedRoute of matched) {
            if (matchedRoute.meta && matchedRoute.meta.breadcrumbs && Array.isArray(matchedRoute.meta.breadcrumbs)) {
                matchedRoute.meta.breadcrumbs.forEach(item => this.createItem(item));
            }
        }
    }
};
__decorate([
    Watch('$route', { deep: true }),
    Watch('organizationName', { deep: true })
], AppBreadcrumbs.prototype, "onRouteUpdate", null);
AppBreadcrumbs = __decorate([
    Component({})
], AppBreadcrumbs);
export default AppBreadcrumbs;
