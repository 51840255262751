"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AwsStorageClassesMap = exports.AwsStorageClass = exports.AwsTypeRestore = exports.StorageType = void 0;
var StorageType;
(function (StorageType) {
    StorageType["Local"] = "Local";
    StorageType["AWS_S3"] = "AWS_S3";
})(StorageType = exports.StorageType || (exports.StorageType = {}));
var AwsTypeRestore;
(function (AwsTypeRestore) {
    AwsTypeRestore["Bulk"] = "Bulk";
    AwsTypeRestore["Standard"] = "Standard";
    AwsTypeRestore["Expedited"] = "Expedited";
})(AwsTypeRestore = exports.AwsTypeRestore || (exports.AwsTypeRestore = {}));
var AwsStorageClass;
(function (AwsStorageClass) {
    AwsStorageClass["Flexible"] = "GLACIER";
    AwsStorageClass["Instant"] = "GLACIER_IR";
    AwsStorageClass["Standard"] = "STANDARD";
    AwsStorageClass["Intelligent_Tiering"] = "INTELLIGENT_TIERING";
    AwsStorageClass["StandardInfequent"] = "STANDARD_IA";
    AwsStorageClass["OneZoneInfequent"] = "ONEZONE_IA";
    AwsStorageClass["Deep"] = "DEEP_ARCHIVE";
    AwsStorageClass["Outposts"] = "OUTPOSTS";
    AwsStorageClass["ReducedRedundancy"] = "REDUCED_REDUNDANCY";
})(AwsStorageClass = exports.AwsStorageClass || (exports.AwsStorageClass = {}));
exports.AwsStorageClassesMap = new Map([
    [AwsStorageClass.Standard, 'Standard'],
    [AwsStorageClass.Intelligent_Tiering, 'Intelligent-Tiering'],
    [AwsStorageClass.StandardInfequent, 'Standard-Infrequent Access'],
    [AwsStorageClass.OneZoneInfequent, 'One Zone-Infrequent Access'],
    [AwsStorageClass.Instant, 'Glacier Instant Retrieval'],
    [AwsStorageClass.Flexible, 'Glacier Flexible Retrieval'],
    [AwsStorageClass.Deep, 'Glacier Deep Archive'],
    [AwsStorageClass.Outposts, 'Outposts'],
    [AwsStorageClass.ReducedRedundancy, 'Reduced Redundancy'],
]);
