import dataSetsRoutes from '@/router/modules/data-sets';
import backupsComparisonRoutes from '@/router/modules/backups-comparison';
import backupsRoutes from '@/router/modules/backups';
import dashboardRoutes from '@/router/modules/dashboard';
import restoreRoutes from '@/router/modules/restore';
import backupToOrgComparisonRoutes from '@/router/modules/backup-to-org-comparison';
import settingsRoutes from '@/router/modules/settings';
import { multiGuard } from '@/utils/multiguard';
import organizationResolver from '@/router/resolvers/organization.resolver';
import extensionResolver from '@/router/resolvers/extension.resolver';
import encryptKeyResolver from '@/router/resolvers/encrypt-key.resolver';
export default [
    {
        path: 'org/:id',
        component: () => import('@/layouts/EmptyRouterView.vue'),
        meta: {
            auth: true,
            breadcrumbs: [],
        },
        children: [
            {
                name: 'organization',
                path: '',
                beforeEnter: multiGuard([organizationResolver, extensionResolver, encryptKeyResolver]),
                component: () => import('@/layouts/EmptyRouterView.vue'),
                children: [
                    ...dataSetsRoutes,
                    ...backupsComparisonRoutes,
                    ...backupsRoutes,
                    ...dashboardRoutes,
                    ...restoreRoutes,
                    ...backupToOrgComparisonRoutes,
                    ...settingsRoutes,
                    {
                        path: 'templates',
                        name: 'org.templates2',
                        component: () => import('@/layouts/EmptyRouterView.vue'),
                        meta: {
                            breadcrumbs: [{ icon: 'template', title: 'Templates', to: 'org.templates2' }],
                        },
                        redirect: { name: 'org.templates2.index' },
                        children: [
                            {
                                path: '',
                                name: 'org.templates2.index',
                                component: () => import('@/pages/templates/index.vue'),
                            },
                            {
                                path: ':templateId',
                                name: 'org.templates2.manage',
                                meta: {
                                    breadcrumbs: [(route) => ({ title: route.params.templateId === 'new' ? 'Create' : 'Edit' })],
                                },
                                component: () => import('@/pages/templates/_id.vue'),
                            },
                        ],
                    },
                ],
            },
        ],
    },
];
