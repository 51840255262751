export default [
    {
        path: '/playground',
        name: 'playground',
        component: () => import('@/layouts/EmptyRouterView.vue'),
        redirect: { name: 'playground.index' },
        children: [
            {
                path: '',
                name: 'playground.index',
                component: () => import('@/pages/playground/index.vue'),
            },
            {
                path: 'icons',
                name: 'playground.icons',
                component: () => import('@/pages/playground/icons.vue'),
            },
        ],
    },
];
