import { __decorate } from "tslib";
import { Vue, Component, Model, Prop } from 'vue-property-decorator';
const PAGINATION_CHANGED_EVENT = 'PAGINATION_CHANGED_EVENT';
const DEFAULT_PER_PAGE_LIMITS = [15, 25, 50];
export const DEFAULT_PAGINATION_LIMIT = DEFAULT_PER_PAGE_LIMITS[1];
let FPagination = class FPagination extends Vue {
    created() {
        if (!this.limits.includes(this.options.limit)) {
            throw new Error(`The limit ${this.options.limit} is not included in perPageLimits!`);
        }
    }
    onLimitChanged() {
        this.$emit(PAGINATION_CHANGED_EVENT, { ...this.options, page: 1 });
    }
    onPageChanged(page) {
        this.$emit(PAGINATION_CHANGED_EVENT, { ...this.options, page });
    }
};
__decorate([
    Model(PAGINATION_CHANGED_EVENT)
], FPagination.prototype, "options", void 0);
__decorate([
    Prop({
        default: () => DEFAULT_PER_PAGE_LIMITS,
    })
], FPagination.prototype, "limits", void 0);
__decorate([
    Prop({
        default: false,
    })
], FPagination.prototype, "hideLimits", void 0);
FPagination = __decorate([
    Component({})
], FPagination);
export default FPagination;
