var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dual-list"},[_c('div',{staticClass:"dual-list__column"},_vm._l((_vm.availableItems),function(item,index){return _c('div',{key:index,on:{"click":function($event){return _vm.pickItem(item)}}},[_vm._t("item-left",function(){return [_c('div',{staticClass:"dual-list__item",class:{
            'dual-list__item--disabled': item.disabled,
            'dual-list__item--picked': item.picked,
          }},[_vm._v(" "+_vm._s(_vm.itemLabel ? item.data[_vm.itemLabel] : item.data)+" ")])]},{"item":item})],2)}),0),_c('div',{staticClass:"dual-list__controls"},[_c('div',{staticClass:"dual-list__icon",class:{ 'dual-list__icon--disabled': !_vm.availableItemsToRight.length },on:{"click":function($event){return _vm.selectAll()}}},[_c('f-icon',{attrs:{"name":"chevron_double_forward"}})],1),_c('div',{staticClass:"dual-list__icon",class:{
        'dual-list__icon--disabled': !_vm.availableItemsToRight.length || !_vm.hasPickedItems || _vm.pickPosition !== 'left',
      },on:{"click":function($event){return _vm.movePickedItems()}}},[_c('f-icon',{attrs:{"name":"chevron_forward"}})],1),_c('div',{staticClass:"dual-list__icon",class:{
        'dual-list__icon--disabled': !_vm.availableItemsToLeft.length || !_vm.hasPickedItems || _vm.pickPosition !== 'right',
      },on:{"click":function($event){return _vm.movePickedItems()}}},[_c('f-icon',{attrs:{"name":"chevron_backward"}})],1),_c('div',{staticClass:"dual-list__icon",class:{ 'dual-list__icon--disabled': !_vm.availableItemsToLeft.length },on:{"click":function($event){return _vm.unselectAll()}}},[_c('f-icon',{attrs:{"name":"chevron_double_backward"}})],1)]),_c('div',{staticClass:"dual-list__column"},_vm._l((_vm.selectedItems),function(item,index){return _c('div',{key:index,on:{"click":function($event){return _vm.pickItem(item)}}},[_vm._t("item-right",function(){return [_c('div',{staticClass:"dual-list__item",class:{
            'dual-list__item--disabled': item.disabled,
            'dual-list__item--picked': item.picked,
          }},[_vm._v(" "+_vm._s(_vm.itemLabel ? item.data[_vm.itemLabel] : item.data)+" ")])]},{"item":item})],2)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }