import backupResolver from '@/router/resolvers/backup.resolver';
import { multiGuard } from '@/utils/multiguard';
import backupObjectResolver from '@/router/resolvers/backup-object.resolver';
import backupObjectRecordResolver from '@/router/resolvers/backup-object-record.resolver';
export default [
    {
        path: 'backups',
        name: 'backups',
        meta: {
            breadcrumbs: [{ icon: 'database', title: 'Backups', to: 'backups' }],
        },
        redirect: { name: 'backups.index' },
        component: () => import('@/layouts/EmptyRouterView.vue'),
        children: [
            {
                path: '',
                name: 'backups.index',
                component: () => import('@/pages/backups/BackupsIndexPage.vue'),
            },
            {
                path: 'new',
                name: 'backups.new',
                meta: {
                    breadcrumbs: [{ title: 'Create' }],
                },
                component: () => import('@/pages/backups/BackupsCreatePage.vue'),
            },
            {
                path: 'logs',
                name: 'backups.logs',
                meta: {
                    breadcrumbs: [{ title: 'Logs' }],
                },
                component: () => import('@/pages/logs/index.vue'),
            },
            {
                path: ':backupId',
                meta: {
                    breadcrumbs: [{ title: 'Details' }],
                },
                beforeEnter: multiGuard([backupResolver]),
                component: () => import('@/layouts/EmptyRouterView.vue'),
                children: [
                    {
                        path: 'objects',
                        meta: {
                            breadcrumbs: [{ title: 'Objects', to: 'backups.backup.objects' }],
                        },
                        component: () => import('@/layouts/EmptyRouterView.vue'),
                        children: [
                            {
                                path: '',
                                name: 'backups.backup.objects',
                                component: () => import('@/pages/backups/BackupObjectsPage.vue'),
                            },
                            {
                                path: ':objectName',
                                component: () => import('@/layouts/EmptyRouterView.vue'),
                                children: [
                                    {
                                        path: 'records',
                                        meta: {
                                            breadcrumbs: [
                                                (route) => ({
                                                    title: route.params.objectName,
                                                    to: 'backups.backup.objects.object.records',
                                                    preserveQuery: true,
                                                }),
                                            ],
                                        },
                                        beforeEnter: multiGuard([backupObjectResolver]),
                                        component: () => import('@/layouts/EmptyRouterView.vue'),
                                        children: [
                                            {
                                                path: '',
                                                name: 'backups.backup.objects.object.records',
                                                component: () => import('@/pages/backups/BackupObjectRecordsPage.vue'),
                                            },
                                            {
                                                path: ':recordId',
                                                meta: {
                                                    breadcrumbs: [
                                                        (route) => ({
                                                            title: route.params.recordId,
                                                            to: 'backups.backup.objects.object.records.record',
                                                            preserveQuery: true,
                                                        }),
                                                    ],
                                                },
                                                beforeEnter: multiGuard([backupObjectRecordResolver]),
                                                component: () => import('@/layouts/EmptyRouterView.vue'),
                                                children: [
                                                    {
                                                        path: '',
                                                        name: 'backups.backup.objects.object.records.record',
                                                        component: () => import('@/pages/backups/BackupObjectRecordViewPage.vue'),
                                                    },
                                                    {
                                                        path: 'history',
                                                        meta: {
                                                            breadcrumbs: [{ title: 'History', icon: 'clock' }],
                                                            preserveQuery: true,
                                                        },
                                                        name: 'backups.backup.objects.object.records.record.history',
                                                        component: () => import('@/pages/backups/BackupObjectRecordHistoryPage.vue'),
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
];
