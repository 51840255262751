import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
let FRecordItem = class FRecordItem extends Vue {
    get safeValue() {
        if (this.value === '') {
            return '-';
        }
        return this.value ?? '-';
    }
};
__decorate([
    Prop()
], FRecordItem.prototype, "label", void 0);
__decorate([
    Prop()
], FRecordItem.prototype, "value", void 0);
FRecordItem = __decorate([
    Component({})
], FRecordItem);
export default FRecordItem;
