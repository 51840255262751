import { __decorate } from "tslib";
import { Vue, Component, Emit, Watch } from 'vue-property-decorator';
let VueModal = class VueModal extends Vue {
    constructor() {
        super(...arguments);
        this.modalState = false;
    }
    show() {
        this.modalState = true;
    }
    close() {
        this.modalState = false;
    }
    onRouteChanged() {
        if (this.modalState) {
            this.close();
            this.resolved(null);
        }
    }
    resolved(data) {
        return data;
    }
};
__decorate([
    Watch('$route', { deep: true })
], VueModal.prototype, "onRouteChanged", null);
__decorate([
    Emit()
], VueModal.prototype, "resolved", null);
VueModal = __decorate([
    Component({})
], VueModal);
export default VueModal;
