export default [
    {
        path: 'data-sets',
        name: 'data-sets',
        meta: {
            breadcrumbs: [{ icon: 'layers', title: 'Data Sets', to: 'data-sets' }],
        },
        component: () => import('@/layouts/EmptyRouterView.vue'),
        redirect: { name: 'data-sets.index' },
        children: [
            {
                path: '',
                name: 'data-sets.index',
                component: () => import('@/pages/data-sets/index.vue'),
            },
            {
                path: ':dataSetId',
                name: 'data-sets.manage',
                meta: {
                    breadcrumbs: [(route) => ({ title: route.params.dataSetid === 'new' ? 'Create' : 'Edit' })],
                },
                component: () => import('@/pages/data-sets/_id.vue'),
            },
        ],
    },
];
