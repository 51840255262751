import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { AppModuleStore } from '@/store/modules/app';
let AppLoading = class AppLoading extends Vue {
};
__decorate([
    AppModuleStore.State
], AppLoading.prototype, "loading", void 0);
__decorate([
    AppModuleStore.State
], AppLoading.prototype, "loadingText", void 0);
AppLoading = __decorate([
    Component({})
], AppLoading);
export default AppLoading;
