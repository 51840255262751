import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let FLoading = class FLoading extends Vue {
};
__decorate([
    Prop({
        default: false,
    })
], FLoading.prototype, "shown", void 0);
__decorate([
    Prop({
        default: false,
    })
], FLoading.prototype, "small", void 0);
FLoading = __decorate([
    Component({})
], FLoading);
export default FLoading;
