import { __decorate } from "tslib";
import { Vue, Component, Model, Prop } from 'vue-property-decorator';
import { DEFAULT_PAGINATION_LIMIT } from '@/constants';
let FPaginator = class FPaginator extends Vue {
    onPageChanged(page) {
        this.$emit('change', page);
    }
};
__decorate([
    Model('change')
], FPaginator.prototype, "page", void 0);
__decorate([
    Prop({
        default: DEFAULT_PAGINATION_LIMIT,
    })
], FPaginator.prototype, "limit", void 0);
__decorate([
    Prop({
        default: 0,
    })
], FPaginator.prototype, "total", void 0);
FPaginator = __decorate([
    Component({})
], FPaginator);
export default FPaginator;
