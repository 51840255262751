import Vue from 'vue';
import { RecordViewModule } from '@/store/modules/record-view';
const backupObjectResolver = async (to, from, next) => {
    const isOriginalRoute = () => Vue.router.currentRoute === from;
    try {
        const { objectName } = to.params;
        const { storageId } = to.query;
        if (!storageId) {
            next(false);
            return;
        }
        if (!RecordViewModule.backup.isRecordViewAccessible) {
            Vue.toast.error('Record view is not supported for this backup');
            next(false);
            return;
        }
        const { storages } = RecordViewModule.backup;
        const backupStorage = storages.find(storage => storage.id === +storageId);
        if (!backupStorage || !backupStorage.isAccessImmediate) {
            Vue.toast.error('Storage type for this backup do not support instant retrieve. Retrieve backup from cloud storage to continue.');
            next(false);
            return;
        }
        RecordViewModule.setBackupStorage(backupStorage);
        if (objectName.endsWith('__b')) {
            Vue.toast.error('Big objects are not supported');
            next(false);
            return;
        }
        Vue.loading(`Loading "${objectName}" object metadata`);
        await RecordViewModule.fetchBackupObject({ objectName });
    }
    catch (error) {
        Vue.toast.error(error.message);
        if (isOriginalRoute()) {
            Vue.loading(false);
        }
        next(false);
    }
};
export default backupObjectResolver;
