export default [
    {
        path: 'backups-comparison',
        name: 'backups-comparison',
        redirect: { name: 'backups-comparison.index' },
        component: () => import('@/layouts/EmptyRouterView.vue'),
        meta: {
            breadcrumbs: [
                { icon: 'arrow_up_down', title: 'Comparison' },
                { title: 'Backup to backup', to: 'backups-comparison' },
            ],
        },
        children: [
            {
                path: '',
                name: 'backups-comparison.index',
                component: () => import('@/pages/backups-comparison/index.vue'),
            },
            {
                path: 'new',
                name: 'backups-comparison.new',
                component: () => import('@/pages/backups-comparison/new.vue'),
                meta: {
                    breadcrumbs: [{ title: 'Create' }],
                },
            },
            {
                path: 'logs',
                name: 'backups-comparison.logs',
                component: () => import('@/pages/logs/index.vue'),
                meta: {
                    breadcrumbs: [{ title: 'Logs' }],
                },
            },
            {
                path: ':comparisonId',
                name: 'backups-comparison.manage',
                component: () => import('@/pages/backups-comparison/_id.vue'),
                meta: {
                    breadcrumbs: [{ title: 'View' }],
                },
            },
        ],
    },
];
