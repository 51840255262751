import Vue from 'vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import Vuelidate from 'vuelidate';
import Notifications from 'vue-notification';
import AlertPlugin from './toast.plugin';
import ConfirmPlugin from './confirm.plugin';
import FComponentsPlugin from './f-components.plugin';
import FiltersPlugin from './filters.plugin';
import DirectivesPlugin from './directives.plugin';
import LoadingPlugin from '@/plugins/loading.plugin';
import ModalPlugin from '@/plugins/modal.plugin';
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Vuelidate);
Vue.use(Notifications);
Vue.use(AlertPlugin);
Vue.use(FComponentsPlugin);
Vue.use(ConfirmPlugin);
Vue.use(FiltersPlugin);
Vue.use(DirectivesPlugin);
Vue.use(LoadingPlugin);
Vue.use(ModalPlugin);
