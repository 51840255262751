import { __decorate } from "tslib";
import { Vue, Component, Prop, Watch, Ref, Emit } from 'vue-property-decorator';
import { getAppURIs } from '@/utils';
let FRecordField = class FRecordField extends Vue {
    constructor() {
        super(...arguments);
        this.isOverflowing = false;
        this.isExpanded = false;
    }
    get safeValue() {
        return this.value ?? '';
    }
    // TODO: move to typed record field?
    get downloadUrl() {
        const { id: organizationId, backupId, objectName } = this.$route.params;
        const { server } = getAppURIs();
        const url = new URL(server);
        const params = new URLSearchParams({ organizationId, storageId: this.storageId });
        url.pathname = `/api/v1/backups/${backupId}/objects/${objectName}/binary/${this.value.replace('#', '')}`;
        url.search = params.toString();
        return url.toString();
    }
    get tooltipText() {
        return `Click to ${this.isExpanded ? 'hide' : 'expand'}`;
    }
    expandValue() {
        this.isExpanded = !this.isExpanded;
        this.$nextTick(() => {
            this.onExpanded();
        });
    }
    async enforceHeight(height) {
        this.isExpanded = !this.isExpanded;
        // if this field value is overflowing (can be expandable)
        // we need to open it and compare the height
        if (this.isOverflowing && this.isExpanded) {
            await this.$nextTick();
            const valueHeight = this.valueElement.clientHeight;
            // if this value height more than provided height
            // trigger expanded event
            if (valueHeight > height) {
                this.onExpanded();
            }
            else {
                this.valueElement.style.height = `${height}px`;
            }
        }
        else if (this.isExpanded) {
            this.valueElement.style.height = `${height}px`;
        }
    }
    onExpanded() {
        return this.valueElement.clientHeight;
    }
    onValueChanged() {
        this.$nextTick(() => {
            this.isOverflowing =
                this.valueElement.clientWidth < this.valueElement.scrollWidth ||
                    this.valueElement.clientHeight < this.valueElement.scrollHeight;
        });
    }
};
__decorate([
    Prop()
], FRecordField.prototype, "label", void 0);
__decorate([
    Prop()
], FRecordField.prototype, "value", void 0);
__decorate([
    Prop({
        default: '',
    })
], FRecordField.prototype, "type", void 0);
__decorate([
    Prop({
        default: '',
    })
], FRecordField.prototype, "storageId", void 0);
__decorate([
    Prop({
        default: false,
    })
], FRecordField.prototype, "highlighted", void 0);
__decorate([
    Ref()
], FRecordField.prototype, "valueElement", void 0);
__decorate([
    Emit()
], FRecordField.prototype, "onExpanded", null);
__decorate([
    Watch('value', { immediate: true })
], FRecordField.prototype, "onValueChanged", null);
FRecordField = __decorate([
    Component({})
], FRecordField);
export default FRecordField;
