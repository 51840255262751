import { __decorate } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import VueModal from '@/classes/vue-modal';
let OrganizationEncryptKeyModal = class OrganizationEncryptKeyModal extends VueModal {
    constructor() {
        super(...arguments);
        this.encryptionKey = '';
    }
    onModalHidden() {
        this.resolved(this.encryptionKey);
    }
};
__decorate([
    Prop()
], OrganizationEncryptKeyModal.prototype, "organizationName", void 0);
OrganizationEncryptKeyModal = __decorate([
    Component({})
], OrganizationEncryptKeyModal);
export default OrganizationEncryptKeyModal;
