"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BackupRecordSearchMode = exports.EncryptCompressOldBackupStatus = exports.BackupTime = exports.BackupMode = void 0;
var BackupMode;
(function (BackupMode) {
    BackupMode["Full"] = "full";
    BackupMode["Template"] = "template";
    BackupMode["Incremental"] = "incremental";
})(BackupMode = exports.BackupMode || (exports.BackupMode = {}));
var BackupTime;
(function (BackupTime) {
    BackupTime["Now"] = "now";
    BackupTime["Repeat"] = "repeat";
})(BackupTime = exports.BackupTime || (exports.BackupTime = {}));
var EncryptCompressOldBackupStatus;
(function (EncryptCompressOldBackupStatus) {
    EncryptCompressOldBackupStatus["NotProcessed"] = "Not Processed";
    EncryptCompressOldBackupStatus["InProcess"] = "In Process";
    EncryptCompressOldBackupStatus["Processed"] = "Processed";
    EncryptCompressOldBackupStatus["Exception"] = "Exception";
})(EncryptCompressOldBackupStatus = exports.EncryptCompressOldBackupStatus || (exports.EncryptCompressOldBackupStatus = {}));
var BackupRecordSearchMode;
(function (BackupRecordSearchMode) {
    BackupRecordSearchMode["Next"] = "next";
    BackupRecordSearchMode["Previous"] = "previous";
})(BackupRecordSearchMode = exports.BackupRecordSearchMode || (exports.BackupRecordSearchMode = {}));
