import { __decorate } from "tslib";
import { Module, VuexModule, getModule, MutationAction, Mutation } from 'vuex-module-decorators';
import { namespace } from 'vuex-class';
import { LICENSE_MODULE_NAMESPACE } from '@/store/modules/license.definitions';
import store from '@/store';
import LicenseApi from '@/services/api/license.api';
let License = class License extends VuexModule {
    constructor() {
        super(...arguments);
        this.licenseChecked = false;
        this.licenseValid = false;
    }
    setLicense(value) {
        this.licenseValid = value;
    }
    async validateLicense() {
        const licenseValid = await LicenseApi.check();
        return {
            licenseChecked: true,
            licenseValid,
        };
    }
    async submitLicense(key) {
        await LicenseApi.submitKey(key);
        return {
            licenseChecked: true,
            licenseValid: true,
        };
    }
};
__decorate([
    Mutation
], License.prototype, "setLicense", null);
__decorate([
    MutationAction({ rawError: true, mutate: ['licenseChecked', 'licenseValid'] })
], License.prototype, "validateLicense", null);
__decorate([
    MutationAction({ rawError: true, mutate: ['licenseChecked', 'licenseValid'] })
], License.prototype, "submitLicense", null);
License = __decorate([
    Module({
        name: LICENSE_MODULE_NAMESPACE,
        namespaced: true,
        dynamic: true,
        store,
    })
], License);
export const LicenseModule = getModule(License);
export const LicenseModuleStore = namespace(LICENSE_MODULE_NAMESPACE);
