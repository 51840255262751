import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
let FModal = class FModal extends Vue {
    constructor() {
        super(...arguments);
        this.modalState = false;
    }
    open() {
        this.modalState = true;
    }
    close() {
        this.modalState = false;
    }
};
FModal = __decorate([
    Component({})
], FModal);
export default FModal;
