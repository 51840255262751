import { AuthModule } from '@/store/modules/auth';
import AuthService from '@/services/auth.service';
const isAuthenticatedGuard = (to, from, next) => {
    if (to.matched.some(record => record.meta.auth) && !AuthModule.isAuthenticated) {
        window.location.replace(AuthService.getLoginUrl(to.fullPath));
        return;
    }
    next();
};
export default isAuthenticatedGuard;
