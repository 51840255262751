import Vue from 'vue';
class EventBus {
    constructor() {
        this.$eventbus = new Vue();
    }
    on(name, handler) {
        this.$eventbus.$on(name, handler);
    }
    emit(event, payload) {
        this.$eventbus.$emit(event, payload);
    }
}
export default new EventBus();
