/**
 * Provide routes with the ability to execute multiple guards.
 * This is implemented due to a gap in Vue Router functionality which limits callbacks to a single guard.
 *
 * @see https://github.com/vuejs/vue-router/issues/2688
 * @param {NavigationGuard[]} guards
 * @return {NavigationGuard}
 */
export const multiGuard = (guards) => async (to, from, next) => {
    // Let sharedNext callback break execution if a guard is triggered.
    let resolved = false;
    // Create a "shared" next callback which breaks the iteration loop if "next()" is called within guard.
    const sharedNext = (location) => {
        if (location !== null && location !== undefined) {
            resolved = true;
            next(location);
        }
    };
    // Iterate through provided guards array until complete or redirected.
    for (const guard of guards) {
        // eslint-disable-next-line no-await-in-loop
        await guard(to, from, sharedNext);
        // Check if the sharedNext callback triggered a break.
        if (resolved) {
            break;
        }
    }
    // If none of the guards triggered a redirect, allow the original intent.
    if (!resolved) {
        next();
    }
};
