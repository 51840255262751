import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import FTab from '@/components/FTab.vue';
let FTabs = class FTabs extends Vue {
    constructor() {
        super(...arguments);
        this.selectedTabIndex = -1;
        this.tabs = [];
    }
    mounted() {
        this.tabs = this.$children.filter(child => child instanceof FTab);
        if (this.tabs.length) {
            this.selectTab(0);
        }
    }
    selectTab(index) {
        this.selectedTabIndex = index;
        this.tabs.forEach((tab, i) => {
            tab.isActive = i === index;
        });
    }
};
FTabs = __decorate([
    Component({})
], FTabs);
export default FTabs;
