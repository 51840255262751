import TemplateDataset from '@/classes/template-dataset';
export const RESET_TEMPLATE_STATE = 'RESET_TEMPLATE_STATE';
export const RESET_TEMPLATE_DATASETS = 'RESET_TEMPLATE_DATASETS';
export const ADD_DATASET_TO_TEMPLATE = 'ADD_DATASET_TO_TEMPLATE';
export const REMOVE_DATASET_FROM_TEMPLATE = 'REMOVE_DATASET_FROM_TEMPLATE';
export const SET_TEMPLATE_GLOBAL_DESCRIBE = 'SET_TEMPLATE_GLOBAL_DESCRIBE';
export const UPDATE_TEMPLATE_DATASETS = 'UPDATE_TEMPLATE_DATASETS';
export const DELETE_TEMPLATE_DATASET = 'DELETE_TEMPLATE_DATASET';
export const SET_TEMPLATE_DATASETS = 'SET_TEMPLATE_DATASETS';
export default {
    [RESET_TEMPLATE_STATE](state) {
        state.selectedDatasets = [];
        state.datasets = [];
        state.describe = null;
    },
    [SET_TEMPLATE_DATASETS](state, payload) {
        state.selectedDatasets = payload;
        state.datasets = payload.map(dataset => new TemplateDataset(dataset, dataset.overlaySteps));
    },
    [UPDATE_TEMPLATE_DATASETS](state) {
        const existedIds = state.datasets.filter(dataset => !!dataset.id).map(dataset => dataset.id);
        const existedNames = state.datasets.map(dataset => dataset.objectApiName);
        const selectedIds = state.selectedDatasets.filter(dataset => !!dataset.id).map(dataset => dataset.id);
        const newDatasets = state.selectedDatasets.filter(dataset => dataset.id === 0 ? !existedNames.includes(dataset.objectApiName) : !existedIds.includes(dataset.id));
        state.datasets = [
            ...state.datasets.filter(dataset => (dataset.id === 0 ? true : selectedIds.includes(dataset.id))),
            ...newDatasets.map(dataset => new TemplateDataset(dataset)),
        ];
    },
    [DELETE_TEMPLATE_DATASET](state, payload) {
        state.datasets = state.datasets.filter(dataset => dataset !== payload);
        state.selectedDatasets = state.selectedDatasets.filter(dataset => dataset.id !== payload.id);
    },
    [SET_TEMPLATE_GLOBAL_DESCRIBE](state, payload) {
        state.describe = payload;
    },
    [RESET_TEMPLATE_DATASETS](state) {
        state.selectedDatasets = [];
    },
    [ADD_DATASET_TO_TEMPLATE](state, payload) {
        state.selectedDatasets = [...state.selectedDatasets, payload];
    },
    [REMOVE_DATASET_FROM_TEMPLATE](state, { id }) {
        state.selectedDatasets = state.selectedDatasets.filter(dataset => dataset.id !== id);
    },
};
