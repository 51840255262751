export const SELECTED_DATASETS = 'SELECTED_DATASETS';
export const TEMPLATE_DATASETS = 'TEMPLATED_DATASETS';
export const GLOBAL_DESCRIBE = 'GLOBAL_DESCRIBE';
export default {
    [SELECTED_DATASETS](state) {
        return state.selectedDatasets;
    },
    [TEMPLATE_DATASETS](state) {
        return state.datasets;
    },
    [GLOBAL_DESCRIBE](state) {
        return state.describe;
    },
};
