export default [
    {
        name: 'restore',
        path: 'restore',
        component: () => import('@/layouts/EmptyRouterView.vue'),
        redirect: { name: 'restore.index' },
        meta: {
            breadcrumbs: [{ icon: 'history', title: 'Restore', to: 'restore.index' }],
        },
        children: [
            {
                name: 'restore.index',
                path: '',
                component: () => import('@/pages/restore/index.vue'),
            },
            {
                name: 'restore.new',
                path: 'new',
                component: () => import('@/pages/restore/new.vue'),
                meta: {
                    breadcrumbs: [{ title: 'Create' }],
                },
            },
            {
                name: 'restore.logs',
                path: 'logs',
                component: () => import('@/pages/logs/index.vue'),
                meta: {
                    breadcrumbs: [{ title: 'Logs' }],
                },
            },
        ],
    },
];
