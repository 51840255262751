import { __decorate } from "tslib";
import { MutationAction, Mutation, Module, VuexModule, getModule } from 'vuex-module-decorators';
import { namespace } from 'vuex-class';
import store from '@/store';
import { RECORD_VIEW_MODULE_NAMESPACE } from '@/store/modules/record-view.definitions';
import BackupsApi from '@/services/api/backups.api';
let RecordView = class RecordView extends VuexModule {
    constructor() {
        super(...arguments);
        this.backup = null;
        this.backupObject = null;
        this.backupStorage = null;
        this.backupObjectDescribe = null;
        this.backupObjectRecord = null;
    }
    reset() {
        this.backup = null;
        this.backupObject = null;
        this.backupStorage = null;
        this.backupObjectRecord = null;
        this.backupObjectDescribe = null;
    }
    setBackupStorage(storage) {
        this.backupStorage = storage;
    }
    async fetchBackup(id) {
        const backup = await BackupsApi.findById(id);
        return { backup };
    }
    async fetchBackupObject(payload) {
        const backupObject = await BackupsApi.findBackupObjectByName(this.backup.id, payload.objectName);
        const backupObjectDescribe = await BackupsApi.findBackupObjectDescribe(this.backup.id, payload.objectName, this.backupStorage.id);
        return {
            backupObject,
            backupObjectDescribe,
        };
    }
    async fetchBackupObjectRecord(payload) {
        const backupObjectRecord = await BackupsApi.findBackupObjectRecord(this.backup.id, this.backupObject.name, payload.recordId, payload.organizationId, this.backupStorage.id);
        return {
            backupObjectRecord,
        };
    }
};
__decorate([
    Mutation
], RecordView.prototype, "reset", null);
__decorate([
    Mutation
], RecordView.prototype, "setBackupStorage", null);
__decorate([
    MutationAction({ rawError: true, mutate: ['backup'] })
], RecordView.prototype, "fetchBackup", null);
__decorate([
    MutationAction({ rawError: true, mutate: ['backupObject', 'backupObjectDescribe'] })
], RecordView.prototype, "fetchBackupObject", null);
__decorate([
    MutationAction({ rawError: true, mutate: ['backupObjectRecord'] })
], RecordView.prototype, "fetchBackupObjectRecord", null);
RecordView = __decorate([
    Module({
        name: RECORD_VIEW_MODULE_NAMESPACE,
        namespaced: true,
        dynamic: true,
        store,
    })
], RecordView);
export const RecordViewModule = getModule(RecordView);
export const RecordViewModuleStore = namespace(RECORD_VIEW_MODULE_NAMESPACE);
