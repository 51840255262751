import { __decorate } from "tslib";
import { Module, VuexModule, getModule, MutationAction } from 'vuex-module-decorators';
import { namespace } from 'vuex-class';
import { AUTH_MODULE_NAMESPACE } from '@/store/modules/auth.definitions';
import store from '@/store';
import OAuthApi from '@/services/api/oauth.api';
let Auth = class Auth extends VuexModule {
    constructor() {
        super(...arguments);
        this.user = null;
    }
    get isAuthenticated() {
        return !!this.user;
    }
    async fetchMe() {
        const user = await OAuthApi.getMe()
            .then(data => data.sub)
            .catch(() => null);
        return {
            user,
        };
    }
};
__decorate([
    MutationAction({ mutate: ['user'] })
], Auth.prototype, "fetchMe", null);
Auth = __decorate([
    Module({
        name: AUTH_MODULE_NAMESPACE,
        namespaced: true,
        dynamic: true,
        store,
    })
], Auth);
export const AuthModule = getModule(Auth);
export const AuthModuleStore = namespace(AUTH_MODULE_NAMESPACE);
