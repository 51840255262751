import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import AppSidebar from '@/components/AppSidebar.vue';
import AppNavbar from '@/components/AppNavbar.vue';
let MainLayout = class MainLayout extends Vue {
    constructor() {
        super(...arguments);
        this.isMobileView = false;
        this.isSidebarShown = false;
    }
    async mounted() {
        this.setViewport();
        window.addEventListener('resize', () => this.setViewport());
    }
    toggleSidebar(value) {
        this.isSidebarShown = value;
    }
    setViewport() {
        this.isMobileView = window.innerWidth < 1321;
        if (!this.isMobileView) {
            this.isSidebarShown = true;
        }
    }
};
MainLayout = __decorate([
    Component({
        components: {
            AppSidebar,
            AppNavbar,
        },
    })
], MainLayout);
export default MainLayout;
