export default {
    // eslint-disable-next-line no-shadow,@typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
    install(Vue) {
        Vue.directive('click-outside', {
            bind(el, binding, vnode) {
                el.clickOutsideEvent = (event) => {
                    if (!(el === event.target || el.contains(event.target))) {
                        const context = vnode.context;
                        const expression = binding.expression;
                        context[expression](event);
                    }
                };
                document.body.addEventListener('click', el.clickOutsideEvent);
            },
            unbind(el) {
                document.body.removeEventListener('click', el.clickOutsideEvent);
            },
        });
    },
};
