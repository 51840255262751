"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RestoreMode = void 0;
/**
 * /
 * */
var RestoreMode;
(function (RestoreMode) {
    RestoreMode["Selective"] = "selective";
    RestoreMode["Basic"] = "basic";
    RestoreMode["Removed"] = "removed";
    RestoreMode["Comparison"] = "comparison";
})(RestoreMode = exports.RestoreMode || (exports.RestoreMode = {}));
