import Vue from 'vue';
import EventBus, { INVALIDATE_LICENSE_EVENT } from '@/services/event-bus';
export default class LicenseInterceptor {
    static async handle(data) {
        EventBus.emit(INVALIDATE_LICENSE_EVENT);
        switch (data.message) {
            case 'ERROR_NO_LICENSE_FOUND':
                await Vue.router.replace({
                    name: 'auth.license',
                });
                throw new Error('License not found');
            case 'ERROR_INVALID_LICENSE':
            default:
                await Vue.router.replace({
                    name: 'auth.expired',
                });
                throw new Error('Your license expired');
        }
    }
}
