export default [
    {
        path: 'settings',
        name: 'organization-settings',
        meta: {
            breadcrumbs: [{ icon: 'server', title: 'Organization Settings' }],
        },
        component: () => import('@/pages/OrganizationSettingsPage.vue'),
    },
];
