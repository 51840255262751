"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PackageStatusEnum = void 0;
var PackageStatusEnum;
(function (PackageStatusEnum) {
    PackageStatusEnum["Installed"] = "Installed";
    PackageStatusEnum["Installing"] = "Installing";
    PackageStatusEnum["Exception"] = "Exception";
    PackageStatusEnum["NotInstalled"] = "Not Installed";
})(PackageStatusEnum = exports.PackageStatusEnum || (exports.PackageStatusEnum = {}));
