import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
let FIcon = class FIcon extends Vue {
};
__decorate([
    Prop()
], FIcon.prototype, "name", void 0);
FIcon = __decorate([
    Component({})
], FIcon);
export default FIcon;
