import { __decorate } from "tslib";
import { Vue, Component, Prop, Model } from 'vue-property-decorator';
let FSearchField = class FSearchField extends Vue {
    onUpdated(text) {
        this.$emit('changed', text);
    }
};
__decorate([
    Prop({
        default: 400,
    })
], FSearchField.prototype, "debounce", void 0);
__decorate([
    Prop({
        default: 'Type to search...',
    })
], FSearchField.prototype, "placeholder", void 0);
__decorate([
    Prop({
        default: '',
    })
], FSearchField.prototype, "value", void 0);
__decorate([
    Model('changed')
], FSearchField.prototype, "text", void 0);
FSearchField = __decorate([
    Component({})
], FSearchField);
export default FSearchField;
