export const HAS_SELECTED_RECORDS = 'HAS_SELECTED_RECORDS';
export const MERGED_OBJECTS = 'MERGED_OBJECTS';
export default {
    [MERGED_OBJECTS](state) {
        return state.objects;
    },
    [HAS_SELECTED_RECORDS](state) {
        const { objects } = state;
        const types = Object.keys(objects);
        for (const type of types) {
            const ids = Object.keys(objects[type]);
            if (ids.length > 0) {
                return true;
            }
        }
        return false;
    },
};
