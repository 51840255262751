import { LicenseModule } from '@/store/modules/license';
const licenseGuard = (to, from, next) => {
    // all redirects to 'auth.*' pass, since all of them related to licensing
    // or loading license
    if (to.name?.startsWith('auth')) {
        return next();
    }
    if (!LicenseModule.licenseChecked) {
        return next({
            name: 'auth.loading',
            query: {
                redirectTo: to.fullPath,
            },
        });
    }
    // if license is invalid we need to ignore all redirects
    if (!LicenseModule.licenseValid) {
        return next(false);
    }
    return next();
};
export default licenseGuard;
