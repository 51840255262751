import Vue from 'vue';
import { RecordViewModule } from '@/store/modules/record-view';
const backupResolver = async (to, from, next) => {
    const isOriginalRoute = () => Vue.router.currentRoute === from;
    try {
        RecordViewModule.reset();
        Vue.loading('Loading backup data');
        await RecordViewModule.fetchBackup(+to.params.backupId);
        if (!isOriginalRoute()) {
            return next(false);
        }
        return next();
    }
    catch (error) {
        Vue.toast.error(error.message);
        if (isOriginalRoute()) {
            Vue.loading(false);
        }
        return next(false);
    }
};
export default backupResolver;
