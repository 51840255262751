import request from '@/request';
export default class OrganizationsApi {
    static async login(body) {
        const { data } = await request.post(`organizations/login`, body);
        return data;
    }
    static async create(body) {
        const { data } = await request.post(`organizations/`, body);
        return data;
    }
    static async refreshAuth(id, body) {
        const { data } = await request.patch(`organizations/${id}/refresh-auth`, body);
        return data;
    }
    static async findById(id) {
        const { data } = await request.get(`organizations/${id}`);
        return data;
    }
    static async delete(id) {
        await request.delete(`organizations/${id}`);
    }
    static async find(params) {
        const { data } = await request.get('organizations', {
            params,
        });
        return data;
    }
    static async getExtensionDetails(id) {
        const { data } = await request.get(`organizations/${id}/extension`);
        return data;
    }
    static async installExtension(id) {
        const { data } = await request.patch(`organizations/${id}/extension`);
        return data;
    }
    static async getKeyStatus(id) {
        const { data } = await request.get(`organizations/${id}/key`);
        return data;
    }
    static async setEncryptKey(id, body) {
        const { data } = await request.put(`organizations/${id}/key`, body);
        return data;
    }
    static async getBackupsStatus(id) {
        const { data } = await request.get(`organizations/${id}/backups`);
        return data;
    }
    static async encryptBackups(id) {
        const { data } = await request.patch(`organizations/${id}/backups`);
        return data;
    }
}
