import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
let FBlock = class FBlock extends Vue {
};
__decorate([
    Prop({
        default: '',
    })
], FBlock.prototype, "title", void 0);
FBlock = __decorate([
    Component({})
], FBlock);
export default FBlock;
