import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let FOverlay = class FOverlay extends Vue {
};
__decorate([
    Prop({
        default: false,
    })
], FOverlay.prototype, "shown", void 0);
__decorate([
    Prop({
        default: false,
    })
], FOverlay.prototype, "small", void 0);
FOverlay = __decorate([
    Component({})
], FOverlay);
export default FOverlay;
