import { formatDate, normalizeBytes, normalizeMode } from '@/utils';
export default {
    // eslint-disable-next-line no-shadow,@typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
    install(Vue) {
        Vue.filter('backupMode', (mode) => {
            return normalizeMode(mode);
        });
        Vue.filter('size', (value) => {
            if (!value)
                return '-';
            return normalizeBytes(+value);
        });
        Vue.filter('date', (value) => {
            if (!value)
                return '-';
            return formatDate(value);
        });
    },
};
