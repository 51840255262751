import EventBus, { APP_LOADING_EVENT } from '@/services/event-bus';
function loading(text) {
    let loadingState = false;
    let loadingText = '';
    if (text === null || text === undefined) {
        loadingState = true;
    }
    if (typeof text === 'boolean') {
        loadingState = text;
    }
    if (typeof text === 'string') {
        loadingText = text;
        loadingState = true;
    }
    EventBus.emit(APP_LOADING_EVENT, { state: loadingState, text: loadingText });
}
export default {
    // eslint-disable-next-line no-shadow,@typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
    install(Vue) {
        Vue.prototype.$loading = loading;
        Vue.loading = loading;
    },
};
