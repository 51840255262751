import { __decorate } from "tslib";
import { Vue, Component, Model, Prop } from 'vue-property-decorator';
let FCheckbox = class FCheckbox extends Vue {
    handleInput(event) {
        const isChecked = event.target.checked;
        this.$emit('change', isChecked);
    }
};
__decorate([
    Model('change', {
        default: false,
    })
], FCheckbox.prototype, "value", void 0);
__decorate([
    Prop({
        default: false,
    })
], FCheckbox.prototype, "disabled", void 0);
FCheckbox = __decorate([
    Component({})
], FCheckbox);
export default FCheckbox;
