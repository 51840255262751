export default [
    {
        path: '/error',
        component: () => import('@/pages/error.vue'),
    },
    {
        path: '/salesforce-callback',
        component: () => import('@/pages/salesforce-callback.vue'),
    },
];
