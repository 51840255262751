export default [
    {
        path: 'dashboard',
        component: () => import('@/pages/dashboard.vue'),
        name: 'dashboard',
        meta: {
            breadcrumbs: [{ icon: 'category', title: 'Dashboard' }],
        },
    },
];
