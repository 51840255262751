import { __decorate } from "tslib";
import { Vue, Component, Prop, Model } from 'vue-property-decorator';
import AppBreadcrumbs from './AppBreadcrumbs.vue';
let AppNavbar = class AppNavbar extends Vue {
};
__decorate([
    Prop()
], AppNavbar.prototype, "mobile", void 0);
__decorate([
    Model('change')
], AppNavbar.prototype, "isSidebarShown", void 0);
AppNavbar = __decorate([
    Component({
        components: {
            AppBreadcrumbs,
        },
    })
], AppNavbar);
export default AppNavbar;
