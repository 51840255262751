import Vue from 'vue';
import './plugins';
import './theme/main.scss';
import './events/register';
import App from './App.vue';
import store from './store';
import router from './router';
Vue.config.productionTip = false;
new Vue({
    router,
    store,
    render: h => h(App),
    validations: {},
}).$mount('#app');
