import request from '@/request';
export default class LicenseApi {
    static getTrial(body) {
        return request.post('license/get-trial', body).then(({ data }) => data);
    }
    static submitKey(key) {
        return request.post('license', { key });
    }
    static check() {
        return request
            .get('license')
            .then(() => true)
            .catch(() => false);
    }
}
