import Vue from 'vue';
import { RecordViewModule } from '@/store/modules/record-view';
const backupObjectRecordResolver = async (to, from, next) => {
    const isOriginalRoute = () => Vue.router.currentRoute === from;
    try {
        const { recordId, id: organizationId } = to.params;
        Vue.loading(`Loading record metadata`);
        await RecordViewModule.fetchBackupObjectRecord({
            recordId,
            organizationId: +organizationId,
        });
    }
    catch (error) {
        Vue.toast.error(error.message);
        if (isOriginalRoute()) {
            Vue.loading(false);
        }
        next(false);
    }
};
export default backupObjectRecordResolver;
