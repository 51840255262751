import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import comparisonMerge from './comparison-merge';
import templates from './templates';
import { RECORD_VIEW_MODULE_NAMESPACE } from '@/store/modules/record-view.definitions';
import { ORGANIZATIONS_MODULE_NAMESPACE } from '@/store/modules/organizations.definitions';
import { LICENSE_MODULE_NAMESPACE } from '@/store/modules/license.definitions';
import { AUTH_MODULE_NAMESPACE } from '@/store/modules/auth.definitions';
import { APP_MODULE_NAMESPACE } from '@/store/modules/app.definitions';
Vue.use(Vuex);
const store = new Store({
    modules: {
        comparisonMerge,
        templates,
    },
});
Vue.store = store;
export default store;
