import Vue from 'vue';
import axios from 'axios';
import { getAppURIs } from '@/utils';
import LicenseInterceptor from '@/interceptors/license.interceptor';
import AuthService from '@/services/auth.service';
import { ERROR_INVALID_LICENSE, ERROR_NO_LICENSE_FOUND, ERROR_UNAUTHORIZED } from '@/constants';
const SERVER_URI = getAppURIs().server;
const request = axios.create({
    baseURL: `${SERVER_URI}/api/v1`,
    withCredentials: true,
});
function responseInterceptor(response) {
    return response;
}
async function errorInterceptor(error) {
    if (error?.config?.baseURL && error.config.baseURL.startsWith(SERVER_URI)) {
        const { response } = error;
        if (!response) {
            throw new Error('Could not receive response from server.');
        }
        const { status, data } = response;
        if (status === 408 || error.code === 'ECONNABORTED') {
            throw new Error(`A timeout happened on url ${error.config.url}`);
        }
        if (!data) {
            throw new Error(`Unknown error`);
        }
        switch (data.message) {
            case ERROR_UNAUTHORIZED: {
                let returnTo = Vue.router.currentRoute.fullPath;
                if (Vue.router.currentRoute?.name === 'auth.loading') {
                    returnTo = Vue.router.currentRoute.params.redirectTo || '/home';
                }
                window.location.replace(AuthService.getLoginUrl(returnTo));
                return Promise.reject(new Error('Unauthorized request'));
            }
            case ERROR_NO_LICENSE_FOUND:
            case ERROR_INVALID_LICENSE:
                return LicenseInterceptor.handle(data);
        }
        // TODO: mode toast error to request instead of catching in each fetch
        // Vue.toast.error(data.message || 'Unhandled error');
        throw new Error(data.message || 'Unhandled error');
    }
    throw error;
}
request.interceptors.response.use(responseInterceptor, errorInterceptor);
export default request;
