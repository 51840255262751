import { __decorate } from "tslib";
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
let FTable = class FTable extends Vue {
    onTableRef() {
        return this.$refs.table;
    }
    mounted() {
        this.onTableRef();
    }
};
__decorate([
    Prop({
        default: () => [],
    })
], FTable.prototype, "fields", void 0);
__decorate([
    Prop({
        default: () => [],
    })
], FTable.prototype, "items", void 0);
__decorate([
    Emit()
], FTable.prototype, "onTableRef", null);
FTable = __decorate([
    Component({
        inheritAttrs: false,
    })
], FTable);
export default FTable;
