import Vue from 'vue';
export class ModalService {
    setContainer(container) {
        this.container = container;
    }
    show(component, props) {
        return new Promise(resolve => {
            const modalComponent = Vue.extend(component);
            this.container.add({
                component: modalComponent,
                resolver: resolve,
                props: props || {},
            });
        });
    }
}
export default {
    // eslint-disable-next-line no-shadow,@typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
    install(Vue) {
        const modal = new ModalService();
        Vue.prototype.$modal = modal;
        Vue.modal = modal;
    },
};
