"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeclarativeFilterOptionsEnum = void 0;
var DeclarativeFilterOptionsEnum;
(function (DeclarativeFilterOptionsEnum) {
    DeclarativeFilterOptionsEnum["Equals"] = "equals";
    DeclarativeFilterOptionsEnum["NotEquals"] = "not equals";
    DeclarativeFilterOptionsEnum["LessThan"] = "less than";
    DeclarativeFilterOptionsEnum["GreaterThan"] = "greater than";
    DeclarativeFilterOptionsEnum["LessOrEquals"] = "less or equals";
    DeclarativeFilterOptionsEnum["GreaterOrEquals"] = "greater or equals";
    DeclarativeFilterOptionsEnum["Contains"] = "contains";
    DeclarativeFilterOptionsEnum["DoesNotContain"] = "does not contain";
    DeclarativeFilterOptionsEnum["StartsWith"] = "starts with";
    DeclarativeFilterOptionsEnum["Includes"] = "includes";
    DeclarativeFilterOptionsEnum["Excludes"] = "excludes";
})(DeclarativeFilterOptionsEnum = exports.DeclarativeFilterOptionsEnum || (exports.DeclarativeFilterOptionsEnum = {}));
