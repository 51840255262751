import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
let FNotifications = class FNotifications extends Vue {
    // eslint-disable-next-line class-methods-use-this
    getIconByType(type) {
        switch (type) {
            case 'success':
                return 'check_circled';
            case 'danger':
                return 'close_circled';
            case 'warning':
                return 'alert_circled';
            default:
                return 'logotype';
        }
    }
};
FNotifications = __decorate([
    Component({})
], FNotifications);
export default FNotifications;
