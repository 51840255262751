export default class TemplateDataset {
    constructor(dataset, overlaySteps) {
        this.dataset = dataset;
        this.overlaySteps = [];
        if (overlaySteps && overlaySteps !== '{}') {
            this.initOverlaySteps(overlaySteps);
        }
    }
    static get sequenceObjects() {
        return TemplateDataset.SequenceDatasets.map(dataset => dataset.objectApiName);
    }
    get id() {
        return this.dataset.id;
    }
    get name() {
        return this.dataset.name;
    }
    get objectApiName() {
        return this.dataset.objectApiName;
    }
    get queryWhereClause() {
        return this.dataset.queryWhereClause;
    }
    get fields() {
        return Object.keys(TemplateDataset.Describe[this.objectApiName].fields);
    }
    get requiredRelationships() {
        return TemplateDataset.Describe[this.objectApiName]?.requiredRelationships;
    }
    get optionalRelationships() {
        return TemplateDataset.Describe[this.objectApiName]?.optionalRelationships;
    }
    get hasParentObjects() {
        return !!this.parentObjects.length;
    }
    get hasChildObjects() {
        return !!this.childObjects.length;
    }
    get hasOverlaySteps() {
        return !!this.overlaySteps.length;
    }
    get hasAnyOptions() {
        return this.hasChildObjects || this.hasParentObjects || this.hasOverlaySteps;
    }
    get parentObjects() {
        return TemplateDataset.Describe[this.objectApiName].parentObjects.filter(object => !TemplateDataset.sequenceObjects.includes(object) && object in TemplateDataset.Describe);
    }
    get childObjects() {
        return TemplateDataset.Describe[this.objectApiName].childObjects.filter(object => !TemplateDataset.sequenceObjects.includes(object) && object in TemplateDataset.Describe);
    }
    updateReferenceFields(datasets) {
        const objectsBefore = datasets.slice(0, datasets.indexOf(this));
        const objectsNames = objectsBefore.map(object => object.objectApiName);
        const referenceFields = this.findReferenceFields(datasets);
        for (const field in referenceFields) {
            if (field in referenceFields) {
                if (referenceFields[field] === null) {
                    this.overlaySteps = this.overlaySteps.filter(step => step.field !== field);
                }
                else {
                    const { to, relationshipName } = referenceFields[field];
                    const showOverlayStep = objectsNames.includes(to);
                    const overlayStep = this.overlaySteps.find(step => step.field === field && step.to === to);
                    if (showOverlayStep) {
                        if (!overlayStep) {
                            this.overlaySteps.push({
                                to,
                                field,
                                relationshipName,
                                selected: false,
                            });
                        }
                    }
                    else {
                        this.overlaySteps = this.overlaySteps.filter(step => step !== overlayStep);
                    }
                }
            }
        }
    }
    prepareOverlaySteps() {
        return JSON.stringify(this.overlaySteps
            .filter(step => step.selected)
            .map(step => ({
            referenceToObject: step.to,
            referenceName: step.relationshipName,
        })));
    }
    initOverlaySteps(overlaySteps) {
        const steps = JSON.parse(overlaySteps);
        const objectFields = Object.values(TemplateDataset.Describe[this.objectApiName].fields);
        this.overlaySteps = steps.map(({ referenceName, referenceToObject }) => ({
            field: objectFields.find(field => field.relationshipName === referenceName)?.name || '',
            to: referenceToObject,
            selected: true,
            relationshipName: referenceName,
        }));
    }
    /**
     * Find reference fields declared as Overlay Steps
     * @private
     * @returns Map where key is field and value is field on which it refers
     */
    findReferenceFields(datasets) {
        const objectFields = TemplateDataset.Describe[this.objectApiName].fields;
        const fieldNames = Object.keys(objectFields);
        const referencedFields = {};
        for (const fieldName of fieldNames) {
            if (objectFields[fieldName].referenceTo.length === 1) {
                const [referenceTo] = objectFields[fieldName].referenceTo;
                const { relationshipName } = objectFields[fieldName];
                const referenceObject = datasets.find(dataset => dataset.objectApiName === referenceTo);
                if (referenceObject &&
                    !/select\s.+(from)\s/.test(referenceObject.queryWhereClause || '') &&
                    !!relationshipName) {
                    referencedFields[fieldName] = {
                        to: referenceTo,
                        relationshipName: objectFields[fieldName].relationshipName,
                    };
                }
                if (!referenceObject) {
                    referencedFields[fieldName] = null;
                }
            }
        }
        return referencedFields;
    }
}
