import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import AppLoading from '@/components/AppLoading.vue';
import AppModalContainer from '@/components/AppModalContainer.vue';
let App = class App extends Vue {
};
App = __decorate([
    Component({
        components: {
            AppModalContainer,
            AppLoading,
        },
    })
], App);
export default App;
