import { MergeSource } from '@/interfaces/comparison2';
export const RESET_MERGE_RESULT = 'RESET_MERGE_RESULT';
export const SET_MERGE_RESULT = 'SET_MERGE_RESULT';
export const REMOVE_MERGED_RECORD = 'REMOVE_MERGED_RECORD';
export const INIT_MERGED_RECORD = 'INIT_MERGED_RECORD';
export default {
    [RESET_MERGE_RESULT](state) {
        state.objects = {};
    },
    [INIT_MERGED_RECORD](state, { fields }) {
        const { objects } = state;
        for (const field of fields) {
            objects[field] = {};
        }
    },
    [SET_MERGE_RESULT](state, { objectName, recordId, fieldName, mergeSource }) {
        const { objects } = state;
        objects[objectName][recordId] ||= {
            Id: MergeSource.Backup,
        };
        if (mergeSource === MergeSource.Null) {
            delete objects[objectName][recordId][fieldName];
        }
        else {
            objects[objectName][recordId][fieldName] = mergeSource;
        }
        const recordFields = Object.keys(objects[objectName][recordId]);
        if (recordFields.length === 0) {
            delete objects[objectName][recordId];
        }
        state.objects = { ...objects };
    },
    [REMOVE_MERGED_RECORD](state, { objectName, recordId }) {
        if (state.objects[objectName]) {
            delete state.objects[objectName][recordId];
            /* Hack to trigger record re-render */
            state.objects[objectName] = { ...state.objects[objectName] };
        }
    },
};
