/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
var ToastVariant;
(function (ToastVariant) {
    ToastVariant["Warning"] = "warning";
    ToastVariant["Success"] = "success";
    ToastVariant["Error"] = "danger";
})(ToastVariant || (ToastVariant = {}));
export class Toast extends Vue {
    show(variant, message, title, duration = 3000) {
        return Vue.notify({
            title,
            text: message,
            duration,
            type: variant,
        });
    }
    error(message, title = 'Error') {
        return this.show(ToastVariant.Error, message, title, 7000);
    }
    success(message, title = 'Success') {
        return this.show(ToastVariant.Success, message, title);
    }
    warning(message, title = 'Warning') {
        return this.show(ToastVariant.Warning, message, title);
    }
}
export default {
    // eslint-disable-next-line no-shadow,@typescript-eslint/explicit-module-boundary-types
    install(Vue) {
        const toastInstance = new Toast();
        Vue.prototype.$toast = toastInstance;
        Vue.toast = toastInstance;
    },
};
