import Vue from 'vue';
import { PackageStatusEnum } from '@flosum-backup/contracts';
import OrganizationsApi from '@/services/api/organizations.api';
import { sleep } from '@/utils';
const EXTENSION_CHECK_DELAY = 2500;
function installExtension(organizationId, isOriginalRoute) {
    const installLoop = async (isFirstRun = false) => {
        if (!isOriginalRoute()) {
            return false;
        }
        try {
            const extensionStatus = await OrganizationsApi.getExtensionDetails(organizationId);
            if (extensionStatus.status === PackageStatusEnum.Installed) {
                return true;
            }
            if ((isFirstRun && extensionStatus.status === PackageStatusEnum.Exception) ||
                extensionStatus.status === PackageStatusEnum.NotInstalled) {
                await OrganizationsApi.installExtension(organizationId);
            }
            else if (extensionStatus.status === PackageStatusEnum.Exception) {
                Vue.toast.error(extensionStatus.errorMessage);
                return false;
            }
            await sleep(EXTENSION_CHECK_DELAY);
            return installLoop(false);
        }
        catch (error) {
            Vue.toast.error(error.message);
            return false;
        }
    };
    return installLoop();
}
const extensionResolver = async (to, from, next) => {
    const isOriginalRoute = () => Vue.router.currentRoute === from;
    const organizationId = +to.params.id;
    Vue.loading('Loading encryption service');
    const isInstalled = await installExtension(organizationId, isOriginalRoute);
    if (isOriginalRoute()) {
        Vue.loading(false);
    }
    if (isInstalled) {
        next();
    }
    else {
        next(false);
    }
};
export default extensionResolver;
