var FDualList_1;
import { __decorate } from "tslib";
import { Vue, Component, Prop, Model, Watch } from 'vue-property-decorator';
const SELECTED_ITEMS_CHANGED_EVENT = 'SELECTED_ITEMS_CHANGED_EVENT';
var PickPosition;
(function (PickPosition) {
    PickPosition["Left"] = "left";
    PickPosition["Right"] = "right";
    PickPosition["Null"] = "null";
})(PickPosition || (PickPosition = {}));
let FDualList = FDualList_1 = class FDualList extends Vue {
    constructor() {
        super(...arguments);
        this.dualListItems = [];
        this.pickedItems = [];
        this.pickPosition = PickPosition.Null;
    }
    get hasPickedItems() {
        return !!this.pickedItems.length;
    }
    get availableItems() {
        return this.dualListItems.filter(item => item.position === PickPosition.Left);
    }
    get selectedItems() {
        return this.dualListItems.filter(item => item.position === PickPosition.Right);
    }
    get availableItemsToRight() {
        return this.availableItems.filter(item => !item.disabled);
    }
    get availableItemsToLeft() {
        return this.selectedItems.filter(item => !item.disabled);
    }
    createDualListItems() {
        this.initItems();
    }
    movePickedItems() {
        if (this.hasPickedItems) {
            this.pickedItems.forEach(item => {
                if (this.pickPosition !== PickPosition.Null) {
                    item.position = this.pickPosition === PickPosition.Left ? PickPosition.Right : PickPosition.Left;
                }
                item.picked = false;
            });
            this.pickedItems = [];
            this.pickPosition = PickPosition.Null;
            this.updateModel();
        }
    }
    pickItem(item) {
        if (item.disabled) {
            return;
        }
        if (!this.multiselect) {
            this.dualListItems.forEach(dualListItem => {
                if (dualListItem !== item) {
                    dualListItem.picked = false;
                }
            });
            this.pickedItems = [];
        }
        item.picked = !item.picked;
        if (item.position !== this.pickPosition) {
            this.pickedItems.forEach(pickedItem => {
                pickedItem.picked = false;
            });
            this.pickedItems = [];
        }
        this.pickPosition = item.position;
        if (!item.picked) {
            this.pickedItems = this.pickedItems.filter(pickedItem => pickedItem !== item);
        }
        else {
            this.pickedItems.push(item);
        }
    }
    selectAll() {
        if (this.availableItemsToRight.length) {
            this.availableItemsToRight.forEach(item => {
                item.position = PickPosition.Right;
                item.picked = false;
            });
            this.updateModel();
            this.pickedItems = [];
        }
    }
    unselectAll() {
        if (this.availableItemsToLeft.length) {
            this.availableItemsToLeft.forEach(item => {
                item.position = PickPosition.Left;
                item.picked = false;
            });
            this.updateModel();
            this.pickedItems = [];
        }
    }
    initItems() {
        const dualListItems = this.items.map((item, index) => {
            return {
                picked: false,
                disabled: this.disabled.includes(item),
                index,
                data: item,
                label: (this.itemLabel ? item[this.itemLabel] : item),
                position: this.itemsModel.includes(item) ? PickPosition.Right : PickPosition.Left,
            };
        });
        this.dualListItems = FDualList_1.sortDualListItems(dualListItems);
    }
    static sortDualListItems(items) {
        const disabled = items.filter(item => item.disabled).sort((a, b) => a.label.localeCompare(b.label));
        const common = items.filter(item => !item.disabled).sort((a, b) => a.label.localeCompare(b.label));
        return [...disabled, ...common];
    }
    updateModel() {
        this.$emit(SELECTED_ITEMS_CHANGED_EVENT, FDualList_1.sortDualListItems(this.selectedItems).map(({ data }) => data));
    }
};
__decorate([
    Model(SELECTED_ITEMS_CHANGED_EVENT, {
        default: () => [],
    })
], FDualList.prototype, "itemsModel", void 0);
__decorate([
    Prop()
], FDualList.prototype, "items", void 0);
__decorate([
    Prop()
], FDualList.prototype, "itemLabel", void 0);
__decorate([
    Prop({
        default: () => [],
    })
], FDualList.prototype, "disabled", void 0);
__decorate([
    Prop({
        default: false,
    })
], FDualList.prototype, "multiselect", void 0);
__decorate([
    Watch('items', { immediate: true, deep: true }),
    Watch('disabled', { deep: true })
], FDualList.prototype, "createDualListItems", null);
FDualList = FDualList_1 = __decorate([
    Component({})
], FDualList);
export default FDualList;
