export default {
    // eslint-disable-next-line no-shadow,@typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
    install(Vue) {
        Vue.prototype.$confirm = function confirm(message = 'Are you sure?', title = '') {
            return this.$bvModal
                .msgBoxConfirm(message, {
                title,
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Yes',
                cancelTitle: 'Cancel',
                headerClass: title ? 'd-none' : '',
                footerClass: 'p-2 border-top-0',
                bodyClass: 'p-3',
                hideHeaderClose: false,
                centered: true,
            })
                .catch(() => false);
        };
    },
};
