import Vue from 'vue';
import OrganizationsApi from '@/services/api/organizations.api';
import OrganizationEncryptKeyModal from '@/components/layouts/OrganizationEncryptKeyModal.vue';
import { OrganizationsModule } from '@/store/modules/organizations';
const encryptKeyResolver = async (to, from, next) => {
    const isOriginalRoute = () => Vue.router.currentRoute === from;
    try {
        Vue.loading('Loading encryption settings');
        const { isKeyInstalled } = await OrganizationsApi.getKeyStatus(+to.params.id);
        if (isKeyInstalled) {
            return next();
        }
        if (!isOriginalRoute()) {
            return next(false);
        }
        const key = await Vue.modal.show(OrganizationEncryptKeyModal, {
            organizationName: OrganizationsModule.currentOrganization?.name || '',
        });
        if (key) {
            const { message } = await OrganizationsApi.setEncryptKey(+to.params.id, { key });
            Vue.toast.success(message);
            if (isOriginalRoute()) {
                return next();
            }
        }
        Vue.loading(false);
        return next(false);
    }
    catch (error) {
        Vue.toast.error(error.message);
        if (isOriginalRoute()) {
            Vue.loading(false);
        }
        return next(false);
    }
};
export default encryptKeyResolver;
