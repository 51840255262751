import { __decorate } from "tslib";
import { Module, VuexModule, getModule, MutationAction } from 'vuex-module-decorators';
import { namespace } from 'vuex-class';
import store from '@/store';
import OrganizationsApi from '@/services/api/organizations.api';
import { ORGANIZATIONS_MODULE_NAMESPACE } from '@/store/modules/organizations.definitions';
let Organizations = class Organizations extends VuexModule {
    constructor() {
        super(...arguments);
        this.organizations = [];
        this.currentOrganization = null;
    }
    async fetchOrganizations() {
        const { organizations } = await OrganizationsApi.find({
            page: 1,
            search: '',
            limit: 50,
        }).catch(() => ({ organizations: [] }));
        return {
            organizations,
        };
    }
    async fetchCurrentOrganization(id) {
        const organization = await OrganizationsApi.findById(id);
        return {
            currentOrganization: organization,
        };
    }
    async deleteOrganization(id) {
        await OrganizationsApi.delete(id);
        return {
            organizations: this.organizations.filter(organization => organization.id !== id),
        };
    }
};
__decorate([
    MutationAction({ rawError: true, mutate: ['organizations'] })
], Organizations.prototype, "fetchOrganizations", null);
__decorate([
    MutationAction({ rawError: true, mutate: ['currentOrganization'] })
], Organizations.prototype, "fetchCurrentOrganization", null);
__decorate([
    MutationAction({ rawError: true, mutate: ['organizations'] })
], Organizations.prototype, "deleteOrganization", null);
Organizations = __decorate([
    Module({
        name: ORGANIZATIONS_MODULE_NAMESPACE,
        namespaced: true,
        dynamic: true,
        store,
    })
], Organizations);
export const OrganizationsModule = getModule(Organizations);
export const OrganizationsModuleStore = namespace(ORGANIZATIONS_MODULE_NAMESPACE);
