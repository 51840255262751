export default [
    {
        path: 'home',
        name: 'main.home',
        component: () => import('@/pages/home/index.vue'),
    },
    {
        path: 'settings',
        name: 'settings',
        meta: {
            breadcrumbs: [{ title: 'Settings', icon: 'settings' }],
        },
        component: () => import('@/pages/GlobalSettings.vue'),
    },
];
