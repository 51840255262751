import request from '@/request';
export default class BackupsApi {
    static find(params) {
        return request
            .get(`/backups`, {
            params,
        })
            .then(({ data }) => data);
    }
    static create(body) {
        return request.post(`/backups`, body).then(() => undefined);
    }
    static findById(id) {
        return request.get(`/backups/${id}`).then(({ data }) => data);
    }
    static findBackupObjects(id) {
        return request.get(`/backups/${id}/objects`).then(({ data }) => data);
    }
    static findBackupObjectByName(backupId, objectName) {
        return request.get(`/backups/${backupId}/objects/${objectName}`).then(({ data }) => data);
    }
    static findBackupObjectDescribe(backupId, objectName, storageId) {
        return request
            .get(`/backups/${backupId}/objects/${objectName}/describe`, {
            params: {
                storageId,
            },
        })
            .then(({ data }) => data);
    }
    static findBackupObjectRecords(backupId, objectName, body) {
        return request.post(`/backups/${backupId}/objects/${objectName}/records`, body).then(({ data }) => data);
    }
    static findBackupObjectRecord(backupId, objectName, recordId, organizationId, storageId) {
        return request
            .get(`/backups/${backupId}/objects/${objectName}/records/${recordId}`, {
            params: {
                organizationId,
                storageId,
            },
        })
            .then(({ data }) => data);
    }
    static findRecord(body) {
        return request.post(`/backups/record-search`, body).then(({ data }) => data);
    }
    static deploy(id, body) {
        return request.post(`/backups/${id}/deploy-to-cloud-storage`, body);
    }
    static retrieve(id, body) {
        return request.post(`/backups/${id}/retrieve-from-cloud-storage`, body);
    }
    static delete(id, storageId) {
        return request
            .delete(`/backups/${id}`, {
            params: {
                storageId,
            },
        })
            .then(() => undefined);
    }
}
