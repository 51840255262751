import { getAppURIs } from '@/utils';
export default class AuthService {
    static getLoginUrl(returnTo) {
        const { server } = getAppURIs();
        const url = new URL(server);
        const params = new URLSearchParams({
            returnTo,
        });
        url.search = params.toString();
        url.pathname = '/api/v1/oauth/login';
        return url.toString();
    }
    static getLogoutUrl() {
        const { server } = getAppURIs();
        const url = new URL(server);
        url.pathname = '/api/v1/oauth/logout';
        return url.toString();
    }
    static async logOut() {
        window.location.replace(AuthService.getLogoutUrl());
    }
}
